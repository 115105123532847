import { css, cx } from 'emotion';
import React from 'react';

const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
  height: calc(100vh - 121px);
  @media (min-width: 768px) {
    height: calc(100vh - 80px);
    min-height: 100%;
    display: grid;
    align-items: start;
    grid-template-rows: 100%;
    grid-template-columns: 1fr;
  }
  @media (min-width: 1200px) {
    padding-right: 0;
    grid-template-columns: 1fr 30%;
  }
  @media (min-width: 768px) {
    padding-right: 0;
    grid-template-columns: Max(25vw, 14.5rem) 1fr Max(25vw, 14.5rem) !important;
  }
`;

export const InnerGrid = props => {
  const { customAlignment, children } = props;
  return <div className={cx(containerStyle, customAlignment)}>{children}</div>;
};
