import { css, cx } from 'emotion';
import React from 'react';

const buttonContainerStyle = css`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const ButtonContainer = props => {
  const { children, customAlignment } = props;
  return <div className={cx(buttonContainerStyle, customAlignment)}>{children}</div>;
};
