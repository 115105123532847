import { css, cx, keyframes } from 'emotion';
import React from 'react';
import Button from 'reactstrap/es/Button';

const buttonStyle = css`
  font-family: Lato, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  height: 1.9237rem;
  letter-spacing: 0.02em;
  line-height: 1.05rem;
  text-transform: uppercase;
  padding: 0.4rem 1rem;
  border: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
const iconStyle = css`
  height: 1rem;
  width: 1rem;
  vertical-align: bottom;
`;
const anim1 = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
`;

const loaderStyle = css`
  top: -1.9rem;
  border-radius: 50%;
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: ${anim1} 1.8s infinite ease-in-out;
  animation: ${anim1} 1.8s infinite ease-in-out;
  :before,
  :after {
    border-radius: 50%;
    width: 0.75rem;
    height: 0.75rem;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: ${anim1} 1.8s infinite ease-in-out;
    animation: ${anim1} 1.8s infinite ease-in-out;
  }
  color: var(--white);
  font-size: 0.75rem;
  margin: 0.3rem auto;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
  }
  :after {
    left: 1.25rem;
  }
  :before {
    left: -1.25rem;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
`;

const primaryButtonStyle = css`
  background-color: var(--primaryBrand);
  color: white;
  flex-grow: 1;
  @media only screen and (min-width: 768px) {
    max-width: 10rem;
  }
`;
const disabledStyle = css`
  background-color: var(--gray);
  cursor: unset;
`;
const disabledBackStyle = css`
  color: var(--gray);
  border: 1px solid var(--gray);
  cursor: unset;
`;
const fullWidthStyle = css`
  width: 100%;
  @media only screen and (min-width: 768px) {
    max-width: 100%;
  }
`;

const secondaryButtonStyle = css`
  color: var(--primaryBrand);
  border: 1px solid var(--primaryBrand);
  margin-right: 1rem;
  @media (min-width: 769px) and (max-width: 780px) {
    margin-right: 0.5rem;
  }
  background: transparent;
`;

const backButtonStyle = css`
  color: var(--primaryBrand);
  border: 1px solid var(--primaryBrand);
  margin-right: 1rem;
  background: transparent;
`;

const sfButtonStyle = css`
  margin-top: 0.625rem;
  background-color: var(--white);
  color: #3e4c59;
  font-size: 1.25rem;
  font-weight: 600;
  font-family: Lato, sans-serif;
  line-height: 1.7;
  padding: 0.313rem;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
`;

export const CustomButton = props => {
  const { handleClick, secondary, fullWidth, label, type, icon, back, disabled, id, loading, salesForce } = props;
  return (
    <Button
      onClick={handleClick}
      type={type}
      disabled={disabled}
      id={id}
      className={
        secondary
          ? cx(buttonStyle, secondaryButtonStyle, fullWidth && fullWidthStyle, disabled && disabledBackStyle)
          : back
          ? cx(buttonStyle, backButtonStyle, fullWidth && fullWidthStyle)
          : salesForce
          ? sfButtonStyle
          : cx(buttonStyle, primaryButtonStyle, fullWidth && fullWidthStyle, disabled && disabledStyle)
      }
    >
      {icon && !loading && <img src={icon} className={iconStyle} alt="info" />} {!loading ? label : ''}
      {loading && <div className={loaderStyle}>Loading...</div>}
    </Button>
  );
};
