import React from 'react';
import { css } from 'emotion';
import downImg from '../../assets/undraw_clean_up_ucm0.svg';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

const containerStyle = css`
  width: 100%;
  text-align: center;
  padding-top: 100px;
  font-family: 'Lato', sans-serif;
  height: calc(100vh - 121px);
`;

const imgStyle = css`
  height: 200px;
  margin-bottom: 50px;
`;

const subtitle = css`
  color: #8f8f90;
  margin-top: 20px;
  font-weight: unset;
`;

export const Maintenance = () => (
  <div className="App">
    <Header />
    <div className={containerStyle}>
      <img src={downImg} alt="down for maintenance" className={imgStyle} />
      <h1>Our website is temporarily offline</h1>
      <h4 className={subtitle}>The site is down for maintenance. Please check back soon</h4>
    </div>
    <Footer />
  </div>
);
