import React from 'react';
import { css, cx } from 'emotion';
import { observer } from 'mobx-react';
import logoUrl from './assets/beautySecundaryLogo.png';
import awardUrl from './assets/insyncAward.png';
import { salesforceStore } from '../../store/salesforce-store';

const containerWrapperStyle = css`
  background: #242427;
  display: flex;
  justify-content: center;
`;

const containerStyle = css`
  background: #242427;
  color: white;
  display: flex;
  font-family: Lato, sans-serif;
  padding: 70px 10px;
  justify-content: space-around;
  width: 100%;
  max-width: 1300px;
  align-items: flex-start;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const imgStyle = css`
  width: 11.25rem;
  height: 4.75rem;
  margin-bottom: 30px;
`;

const sectionStyle = css`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
`;

const textStyle = css`
  color: white;
  font-family: Lato, sans-serif;
  margin-bottom: 20px;
  line-height: 1.5em;
  font-size: 15px;
  font-weight: 400;
`;

const linkStyle = css`
  color: white;
  opacity: 1;
  text-decoration: none;
`;

const smallTextStyle = css`
  font-size: 11px;
`;

const bigTextStyle = css`
  font-size: 28px;
  line-height: 1.4em;
  text-transform: uppercase;
  color: white;
`;

const iconLinkStyle = css`
  line-height: 30px;
  margin-bottom: 20px;
`;

const awardImgStyle = css`
  width: min(23.75rem, 100%);
  margin-bottom: 20px;
`;

const noMarginStyle = css`
  margin-bottom: 0;
`;

export const Footer = observer(() => {
  const marketingUrl =
    salesforceStore.salesforceOpportunityId || salesforceStore.salesforceLeadId
      ? `${process.env.REACT_APP_MARKETING_FORM}/${salesforceStore.salesforceLeadId ? 'lead' : 'opportunity'}/${
          salesforceStore.salesforceLeadId ? salesforceStore.salesforceLeadId : salesforceStore.salesforceOpportunityId
        }`
      : process.env.REACT_APP_MARKETING_FORM;

  return (
    <div className={containerWrapperStyle}>
      <div className={containerStyle}>
        <div className={sectionStyle}>
          <img src={logoUrl} alt="logo" className={imgStyle} />
          <p className={textStyle}>
            Insync Insurance Solutions Ltd 9 Albany Park, Cabot Lane <br />
            Poole, Dorset BH17 7BX
          </p>
          <p className={cx(textStyle, noMarginStyle)}>t. 0330 124 0730</p>
          <p className={textStyle}>
            e.{' '}
            <a className={linkStyle} href="mailto:hello@insyncinsurance.co.uk">
              hello@insyncinsurance.co.uk
            </a>
          </p>
        </div>
        <div className={sectionStyle}>
          <p className={cx(textStyle, bigTextStyle)}>CUSTOMER SUPPORT</p>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href="https://insyncinsurance.co.uk/get-a-quote"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get A Quote
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href={marketingUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Change Marketing Settings
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            href="https://insyncinsurance.co.uk/bitnami/wordpress/wp-content/uploads/insynccentraldocs/centraldocs/Insync%20Privacy%20Notice.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Data Protection Policy
          </a>
          <a
            className={cx(textStyle, linkStyle, iconLinkStyle)}
            target="_blank"
            rel="noopener noreferrer"
            href="https://insyncinsurance.co.uk/bitnami/wordpress/wp-content/uploads/docs/Terms%20of%20Business.pdf"
          >
            Terms Of Business
          </a>
        </div>
        <div className={sectionStyle}>
          <img src={awardUrl} alt="award" className={awardImgStyle} />
          <p className={cx(textStyle, smallTextStyle)}>
            © Insync Insurance Solutions Ltd 2018 All rights reserved. Insync Insurance Solutions Ltd is authorised &
            regulated by the Financial Conduct Authority where our reference number is 766691. Our registered office is
            7th Floor, Corn Exchange, 55 Mark Lane, London, EC3R 7NE and we are registered in England under company
            number 08810662. Should you have cause to complain, and you are not satisfied with our response to your
            complaint, you may be able to refer it to the Financial Ombudsman Service, which can be contacted as
            follows: The Financial Ombudsman Service Exchange Tower, London, E14 9SR | Tel: 0800 023 4567 or 01200
            309516 | www.financial-ombudsman.org.uk |
            <a
              href="https://insyncinsurance.co.uk/bitnami/wordpress/wp-content/uploads/docs/Terms%20of%20Business.pdf"
              className={linkStyle}
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              Terms of Business
            </a>
          </p>
        </div>
      </div>
    </div>
  );
});
