import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Loader } from './components/components/Loader';
import { InnerGrid } from './components/components/InnerGrid';
import ReferralScreen from './components/Referral/ReferralScreen';
import { Maintenance } from './components/Maintenance/Maintenance';

const Main = lazy(() => import('./components/Main/Main'));
const ErrorPage = lazy(() => import('./components/Error/ErrorPage'));
const SuccessPage = lazy(() => import('./components/Success/SuccessPage'));
const SavedQuote = lazy(() => import('./components/SavedQuote/SavedQuote'));

export const App = () => {
  const history = useHistory();
  useEffect(
    () =>
      history.listen(() => {
        if (history.action === 'POP') {
          history.go(0);
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );
  return (
    <Suspense
      fallback={
        <InnerGrid>
          <div />
          <Loader message="Please wait..." />
        </InnerGrid>
      }
    >
      <Switch>
        <Route exact path="/error">
          <ErrorPage />
        </Route>
        <Route exact path="/success">
          <SuccessPage />
        </Route>
        <Route exact path="/referral">
          <ReferralScreen />
        </Route>
        <Route exact path="/quoteSaved">
          <SavedQuote />
        </Route>
        <Route exact path="/maintenance">
          <Maintenance />
        </Route>
        <Route path="/">
          <Main />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
    </Suspense>
  );
};
