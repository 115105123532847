import { css } from 'emotion';
import React from 'react';

const containerStyle = css`
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 1rem 1rem 0;
  height: fit-content;
  min-height: calc(100vh - 120px);
  @media (min-width: 768px) {
    width: 100%;
    min-height: calc(100vh - 112px);
    padding: 1rem 2rem 0;
  }
`;

export const Container = props => {
  const { children } = props;
  return <div className={containerStyle}>{children}</div>;
};
