import React from 'react';
import { css } from 'emotion';
import { useHistory } from 'react-router-dom';
import { CustomButton } from '../components/CustomButton';
import { ButtonContainer } from '../components/ButtonContainer';
import { Container } from '../components/Container';
import InfoIcon from '../../assets/infoIcon.svg';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { salesforceStore } from '../../store/salesforce-store';

const contentContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    max-width: 100%;
  }
  height: 100%;
  flex-grow: 1;
  text-align: center;
`;
const imgStyle = css`
  width: 128px;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;
const textStyle = css`
  font-family: Lato, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.875rem;
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.03em;
  margin-bottom: 1.5rem;
  color: var(--dark);
  justify-content: center;
`;

const pageWidth = css`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: 'aside main main';
  background-color: var(--white);
  height: 100%;
  flex: 1;
  max-height: calc(100vh - 121px);

  @media (min-width: 1201px) {
    grid-template-areas: 'aside main info';
  }

  @media (max-width: 768px) {
    grid-template-areas: 'main';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;
const centeredTextStyle = css`
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1rem;

  > a {
    color: var(--primaryBrand);
  }
`;

const ReferralScreen = () => {
  const history = useHistory();
  return (
    <div className="App">
      <Header />
      <div className={pageWidth}>
        <Container>
          <div className={contentContainer}>
            <img src={InfoIcon} className={imgStyle} alt="info icon" />
            <h1 className={textStyle}>Thank you for using Insync for your business insurance quotation.</h1>
            <p className={centeredTextStyle}>
              Your quote has gone into referral, to offer you the best quote based on your level of expertise we will
              contact you to obtain further information. If you do have any questions, please contact us on{' '}
              <a href="tel:0330 124 0730">0330 124 0730</a> or email us at{' '}
              <a href="mailto:hello@insyncinsurance.co.uk">hello@insyncinsurance.co.uk</a>.
            </p>
          </div>
          <ButtonContainer>
            <CustomButton
              fullWidth
              handleClick={() => {
                history.replace(
                  salesforceStore.salesforceOpportunityId
                    ? `/opportunity/${salesforceStore.salesforceOpportunityId}`
                    : '/'
                );
                history.go(0);
              }}
              label="BACK TO HOME"
            />
          </ButtonContainer>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default ReferralScreen;
